import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DateFilterType } from '@core/enum/date';
import { TimeHandler } from '@core/services/time-handler.service';
import { TranslateModule } from '@ngx-translate/core';
import moment from 'moment';
import { CalendarModule } from 'primeng/calendar';
import { FloatLabelModule } from 'primeng/floatlabel';
import { InputTextModule } from 'primeng/inputtext';
import { OverlayModule } from 'primeng/overlay';
import { ButtonComponent } from '../button/button.component';

@Component({
  selector: 'app-custom-calendar',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    OverlayModule,
    CalendarModule,
    ButtonComponent,
    TranslateModule,
    InputTextModule,
    FloatLabelModule
  ],
  templateUrl: './custom-calendar.component.html',
  styleUrl: './custom-calendar.component.scss'
})
export class CustomCalendarComponent implements OnChanges {
  @Input() title = '';
  @Input() maxDate?: Date;
  @Input() isInput: boolean = false;
  @Input() datesSelected: Array<Date | undefined> = [];
  @Input() appendTo: 'body' | HTMLElement | undefined = undefined;
  @Output() datesSelectedChange = new EventEmitter<Array<Date | undefined>>();

  visible = false;
  dateFrom: Date | undefined;
  dateTo: Date | undefined;
  rangeDates: Array<Date | undefined> = [];
  th = inject(TimeHandler);

  DFActive?: DateFilterType;
  DFSelected?: DateFilterType;

  DF = [
    { value: DateFilterType.TODAY },
    { value: DateFilterType.YESTERDAY },
    { value: DateFilterType.THIS_WEEK },
    { value: DateFilterType.LAST_WEEK },
    { value: DateFilterType.THIS_MONTH },
    { value: DateFilterType.LAST_MONTH },
    { value: DateFilterType.THIS_YEAR },
    { value: DateFilterType.LAST_YEAR }
  ];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.['datesSelected']?.currentValue?.length === 0) {
      this.dateFrom = undefined;
      this.dateTo = undefined;
      this.rangeDates = [];
      this.DFSelected = undefined;
      this.DFActive = undefined;
    }
  }

  cancelDateFilter() {
    this.visible = false;

    this.rangeDates = [...this.datesSelected];
    this.dateTo = this.rangeDates[0];
    this.dateFrom = this.rangeDates[1];

    this.DFSelected = this.DFActive;
  }

  applyDateFilter() {
    this.visible = false;

    if (this.rangeDates.length === 2) {
      this.datesSelected = [...(this.rangeDates as Date[])];
      this.datesSelectedChange.emit(this.datesSelected);
    }

    this.DFActive = this.DFSelected;
  }

  rangeDateToString(): string {
    const dateArr = this.datesSelected.filter(date => !!date).map(date => this.th.getDateValue(date as Date));
    return dateArr.join(' - ') ? dateArr.join(' - ') : '';
  }

  getDateFilterArray(dateArr: Date[] | undefined[]) {
    [this.dateFrom, this.dateTo] = dateArr;
  }

  setDateFilter() {
    if (!this.dateFrom && !this.dateTo) return;
    if (!this.dateFrom) {
      this.dateFrom = this.dateTo;
      this.dateTo = undefined;
    }
    if (!this.dateTo) {
      this.dateTo = this.dateFrom;
    }
    if (this.dateTo! < this.dateFrom!) this.dateTo = undefined;
    this.rangeDates = [this.dateFrom || this.dateTo, this.dateTo];
  }

  filterDate(value: DateFilterType) {
    this.DFSelected = value;
    let date1, date2: Date | undefined;
    const oneDayQuantity = 1000 * 60 * 60 * 24;
    switch (value) {
      case DateFilterType.TODAY:
        date1 = moment().startOf('date').toDate();
        date2 = moment().endOf('date').toDate();
        break;
      case DateFilterType.YESTERDAY:
        date1 = moment().subtract(1, 'day').startOf('date').toDate();
        date2 = moment().subtract(1, 'day').endOf('date').toDate();
        break;
      case DateFilterType.THIS_WEEK:
        date1 = moment().startOf('week').toDate();
        date2 = moment().endOf('week').toDate();
        break;
      case DateFilterType.LAST_WEEK:
        date1 = moment().subtract(1, 'weeks').startOf('week').toDate();
        date2 = moment().subtract(1, 'weeks').endOf('week').toDate();
        break;
      case DateFilterType.THIS_MONTH:
        date1 = moment().startOf('month').toDate();
        date2 = moment().endOf('month').toDate();
        break;
      case DateFilterType.LAST_MONTH:
        date1 = moment().subtract(1, 'months').startOf('month').toDate();
        date2 = moment().subtract(1, 'months').endOf('month').toDate();
        break;
      case DateFilterType.THIS_YEAR:
        date1 = moment().startOf('year').toDate();
        date2 = moment().endOf('year').toDate();
        break;
      case DateFilterType.LAST_YEAR:
        date1 = moment().subtract(1, 'years').startOf('year').toDate();
        date2 = moment().subtract(1, 'years').endOf('year').toDate();
        break;
      case DateFilterType.ALL_TIME:
        // Maximum Date value according to Mozilla specification
        const maxDateValue = 8.64e15;

        date1 = new Date(0);
        date2 = new Date(maxDateValue);
        break;
    }
    this.rangeDates = [date1, date2];
    [this.dateFrom, this.dateTo] = [date1, date2];
  }

  onSelect() {
    this.DFSelected = undefined;
  }

  onToggleVisible() {
    this.visible = !this.visible;
  }
}
